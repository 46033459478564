// @flow
import React from 'react';

const Mark = () => (
   <svg xmlns='http://www.w3.org/2000/svg' width='14.638' height='21.248'>
      <g transform='translate(-272 -384.89)'>
         <path d='M286.292 390.179a6.7 6.7 0 0 0-.344-.851A7.279 7.279 0 0 0 272 391.482v.906c0 .038.013.378.031.549.265 2.114 1.934 4.362 3.181 6.478 1.341 2.266 2.732 4.5 4.111 6.724.851-1.455 1.7-2.928 2.528-4.345.227-.415.49-.83.716-1.226.151-.265.44-.529.572-.775 1.341-2.455 3.5-4.929 3.5-7.366v-1a7.54 7.54 0 0 0-.346-1.246zm-7.014 4.551a2.6 2.6 0 0 1-2.487-1.775 2.462 2.462 0 0 1-.07-.662v-.586a2.46 2.46 0 0 1 2.639-2.417 2.675 2.675 0 0 1 2.679 2.721 2.743 2.743 0 0 1-2.761 2.719zm0 0' />
      </g>
   </svg>
);

export default Mark;
