// @flow
import React from 'react';
import { injectIntl } from 'react-intl';

import styles from './styles.module.scss';
import Label from '#/Label/Label';
import Paragraph from '#/Paragraph';
import SectionHeading from '#/SectionHeading/SectionHeading';
import Image from './Image';

const About = ({ intl }: { intl: any }) => (
   <section className={styles.about} id='about'>
      <div className={`${styles.content} container`}>
         <div className={styles.textWrapper}>
            <Label>{intl.formatMessage({ id: 'about.label' })}</Label>
            <SectionHeading className={styles.heading}>
               {intl.formatMessage({ id: 'about.heading' })}
            </SectionHeading>
            <Paragraph>
               {intl.formatMessage({ id: 'about.paragraph' })}
            </Paragraph>
         </div>
         <Image />
      </div>
   </section>
);

export default injectIntl(About);
