// @flow
import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image/withIEPolyfill';
import styles from './styles.module.scss';

const Image = () => (
   <StaticQuery
      query={graphql`
         query {
            image: file(relativePath: { eq: "samuel-zeller-unsplash.jpg" }) {
               childImageSharp {
                  fluid(
                     maxWidth: 4160
                     quality: 85
                     srcSetBreakpoints: [1170, 2080, 3372]
                  ) {
                     ...GatsbyImageSharpFluid
                  }
               }
            }
         }
      `}
      render={({ image }) => {
         const fluid = {
            ...image.childImageSharp.fluid,
            sizes: '100vw',
         };
         return (
            <div className={styles.background}>
               <Img
                  critical
                  objectFit='cover'
                  objectPosition='50% 0'
                  style={{ position: 'absolute' }}
                  imgStyle={{ objectPosition: 'top center' }}
                  fluid={fluid}
               />
            </div>
         );
      }}
   />
);

export default Image;
