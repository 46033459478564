// @flow
import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { injectIntl } from 'react-intl';

import styles from './styles.module.scss';
import Label from '#/Label/Label';
import SectionHeading from '#/SectionHeading/SectionHeading';
import Number from './Number';

const query = graphql`
   query {
      image: file(relativePath: { eq: "annie-spratt-unsplash.jpg" }) {
         childImageSharp {
            fluid(
               maxWidth: 4160
               quality: 85
               srcSetBreakpoints: [1170, 2080, 3372]
            ) {
               ...GatsbyImageSharpFluid
            }
         }
      }
   }
`;

const Stats = ({ intl }: { intl: any }) => (
   <StaticQuery
      query={query}
      render={({ image }) => (
         <section className={styles.section}>
            <div className={styles.background}>
               <Img
                  style={{ position: 'absolute' }}
                  fluid={image.childImageSharp.fluid}
               />
            </div>
            <div className={`${styles.content} container`}>
               <Label>{intl.formatMessage({ id: 'stats.label' })}</Label>
               <SectionHeading className={styles.heading}>
                  {intl.formatMessage({ id: 'stats.heading' })}
               </SectionHeading>
               <div className={styles.numbers}>
                  <Number
                     key={1}
                     heading={intl.formatMessage({ id: 'stats.stat1.label' })}
                     number={intl.formatMessage({ id: 'stats.stat1.number' })}
                  />
                  <Number
                     key={2}
                     heading={intl.formatMessage({ id: 'stats.stat2.label' })}
                     number={intl.formatMessage({ id: 'stats.stat2.number' })}
                  />
                  <Number
                     key={3}
                     heading={intl.formatMessage({ id: 'stats.stat3.label' })}
                     number={intl.formatMessage({ id: 'stats.stat3.number' })}
                  />
               </div>
            </div>
         </section>
      )}
   />
);

export default injectIntl(Stats);
