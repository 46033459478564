// @flow
import React from 'react';
import { Provider } from '%/context';
import IntlProvider from '%/intlProvider';

import '../index.scss';
import Header from '#/Header';
import Footer from '#/Footer';
import SEO from '#/seo';
import Homepage from '../Home';

type Props = {|
   pageContext: {
      locale: 'cs' | 'en',
      intlLink: string,
   },
|};

const IndexPage = ({ pageContext }: Props) => (
   <Provider
      value={{
         project: false,
         locale: pageContext.locale,
      }}
   >
      <IntlProvider>
         <>
            <SEO title='Ungelt Group' lang={pageContext.locale} />
            <Header intlLink={pageContext.intlLink} />
            <Homepage />
            <Footer />
         </>
      </IntlProvider>
   </Provider>
);

export default IndexPage;
