// @flow
import React from 'react';

import styles from './styles.module.scss';

const ArrowVertical = () => (
   <svg
      className={styles.arrowIcon}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 10 70'
   >
      <path className={styles.line} d='M 5,0 l0,65' />
      <path d='M 5,0 l3.8,5' />
      <path d='M 5,0 l-3.8,5' />
   </svg>
);

export default ArrowVertical;
