// @flow
import * as React from 'react';

import styles from './styles.module.scss';

type Props = {|
   children: React.Node,
   text: string,
   text2: string,
   link: string,
   link2: string,
|};

const ContactItem = ({ children, text, text2, link, link2 }: Props) => (
   <div className={styles.item}>
      {children}
      <a target='_blank' rel='noopener noreferrer' href={link}>
         {text}
      </a>
      {text2 && link2 && (
         <a target='_blank' rel='noopener noreferrer' href={link2}>
            {text2}
         </a>
      )}
   </div>
);

export default ContactItem;
