// @flow
import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image/withIEPolyfill';
import { injectIntl } from 'react-intl';

import Arrow from '#/Arrow/Arrow';
import styles from './styles.module.scss';
import createMarkup from '%/createMarkup';

type Props = {|
   path: string,
   title: string,
   image: any,
   intl: any,
|};

const Project = ({ path, title, image, intl }: Props) => (
   <Link className={styles.project} to={path}>
      <Img
         style={{ position: 'absolute' }}
         className={styles.image}
         fluid={image}
         objectFit='cover'
         objectPosition='50% 50%'
      />
      <div className={styles.content}>
         <h4 dangerouslySetInnerHTML={createMarkup(title)} />
         <div className={styles.wrapper}>
            <span>
               {intl.formatMessage({
                  id: 'projects.cta',
               })}
            </span>
            <Arrow />
         </div>
      </div>
   </Link>
);

export default injectIntl(Project);
