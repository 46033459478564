// @flow
import React from 'react';

import styles from './styles.module.scss';

type Props = {|
   number: string,
   heading: string,
|};

const StatsNumber = ({ number, heading }: Props) => (
   <div className={styles.number}>
      <span>{number}</span>
      <h6>{heading}</h6>
   </div>
);

export default StatsNumber;
