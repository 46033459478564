// @flow
import React from 'react';
import Img from 'gatsby-image/withIEPolyfill';

import Label from '#/Label/Label';
import Paragraph from '#/Paragraph';
import styles from './styles.module.scss';
import createMarkup from '%/createMarkup';

type Props = {|
   label: string,
   heading: string,
   text: string,
   image: any,
   large?: boolean,
|};

const InfoItem = ({ label, heading, text, image, large }: Props) => (
   <div className={styles.item}>
      <div className={styles.textWrapper}>
         <Label className={`${large ? styles.label : ''}`}>{label}</Label>
         <h4 dangerouslySetInnerHTML={createMarkup(heading)} />
         <Paragraph>{text}</Paragraph>
      </div>
      <div className={styles.imgWrapper}>
         <Img
            imgStyle={{ transition: 'opacity 0.5s ease 0s, filter 0.3s' }}
            fluid={image}
            objectFit='cover'
            objectPosition='50% 50%'
         />
      </div>
   </div>
);

export default InfoItem;
