// @flow
import * as React from 'react';

import styles from './styles.module.scss';
import createMarkup from '%/createMarkup';

type Props = {|
   children: string,
   className?: string,
|};

const SectionHeading = ({ children, className }: Props) => (
   <h5
      dangerouslySetInnerHTML={createMarkup(children)}
      className={`${styles.heading} ${className || ''}`}
   />
);

export default SectionHeading;
