// @flow
import React, { Component } from 'react';

import { Consumer } from '%/context';
import Button from '#/SliderArrow';
import styles from './styles.module.scss';
import Project from '../Project';

const queries = [
   '(max-width: 500px)',
   '(max-width: 767px) and (min-width: 501px)',
   '(max-width: 1179px) and (min-width: 768px)',
   '(max-width: 1459px) and (min-width: 1180px)',
   '(min-width: 1460px)',
];

const trimmedQueries = queries.map(query => query.replace(/ /g, ''));

type Props = {|
   projects: Array<{
      node: {
         slug: string,
         title: { cs: string, en: string },
         thumb: {
            childImageSharp: {
               fluid: any,
            },
         },
      },
   }>,
|};

type State = {|
   index: number,
   slideCount: number,
|};

export default class ProjectSlider extends Component<Props, State> {
   state = {
      index: 0,
      slideCount: 0,
   };

   queries = [];

   componentDidMount() {
      for (let i = 0; i < queries.length; i += 1) {
         const query = window.matchMedia(queries[i]);
         this.queries.push(query);
         this._screenTest(query);
         query.addListener(this._screenTest);
      }
   }

   componentWillUnmount() {
      for (let i = 0; i < this.queries.length; i += 1) {
         this.queries[i].removeListener(this._screenTest);
      }
   }

   _screenTest = ({ media, matches }: { media: string, matches: boolean }) => {
      if (!matches) return;
      const { length } = this.props.projects;
      this.setState({
         index: 0,
      });
      const modifiedMedia = media.replace('all and ', '').replace(/ /g, '');
      switch (modifiedMedia) {
         case trimmedQueries[0].replace(/ /g, ''):
            this.setState({
               slideCount: length - 1,
            });
            break;
         case trimmedQueries[1].replace(/ /g, ''):
            this.setState({
               slideCount: length - 2,
            });
            break;
         case trimmedQueries[2].replace(/ /g, ''):
            this.setState({
               slideCount: length - 3,
            });
            break;
         case trimmedQueries[3].replace(/ /g, ''):
            this.setState({
               slideCount: length - 4,
            });
            break;
         case trimmedQueries[4].replace(/ /g, ''):
            this.setState({
               slideCount: length - 5,
            });
            break;
         default:
            this.setState({
               slideCount: 0,
            });
      }
   };

   _handleLeftClick = () => {
      this.setState(({ index }) => {
         if (index === 0) return { index };
         return {
            index: index - 1,
         };
      });
   };

   _handleRightClick = () => {
      const { length } = this.props.projects;
      this.setState(({ index }) => {
         if (index === length - 1) return;
         return {
            index: index + 1,
         };
      });
   };

   render() {
      const { index, slideCount } = this.state;
      const { projects } = this.props;
      const { length } = projects;
      const perc = parseFloat((100 / length).toFixed(3));
      return (
         <Consumer>
            {({ locale }) => (
               <div className={styles.projects}>
                  <div
                     className={styles.inner}
                     style={{
                        transform: `translateX(-${index * perc}%)`,
                     }}
                  >
                     {projects.map(({ node }) => (
                        <Project
                           key={node.slug}
                           path={
                              locale === 'cs' ? node.slug : `/en${node.slug}`
                           }
                           title={
                              locale === 'cs' ? node.title.cs : node.title.en
                           }
                           image={node.thumb.childImageSharp.fluid}
                        />
                     ))}
                  </div>
                  <Button
                     handler={this._handleLeftClick}
                     disabled={index === 0}
                  />
                  <Button
                     handler={this._handleRightClick}
                     right
                     disabled={
                        index === length ||
                        slideCount === 0 ||
                        slideCount === index
                     }
                  />
               </div>
            )}
         </Consumer>
      );
   }
}
