// @flow
import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { injectIntl } from 'react-intl';

import Label from '#/Label/Label';
import Slider from './Slider';
import styles from './styles.module.scss';

const query = graphql`
   {
      allProjectsJson {
         edges {
            node {
               title {
                  cs
                  en
               }
               slug
               thumb {
                  childImageSharp {
                     fluid(maxWidth: 700) {
                        ...GatsbyImageSharpFluid
                     }
                  }
               }
            }
         }
      }
   }
`;

const Projects = ({ intl }: { intl: any }) => (
   <StaticQuery
      query={query}
      render={({ allProjectsJson }) => {
         return (
            <section className={styles.section} id='projects'>
               <div className='container'>
                  <Label className={styles.heading}>
                     {intl.formatMessage({
                        id: 'projects.label',
                     })}
                  </Label>
               </div>
               <Slider projects={allProjectsJson.edges} />
            </section>
         );
      }}
   />
);

export default injectIntl(Projects);
