// @flow
import React from 'react';

const Phone = () => (
   <svg xmlns='http://www.w3.org/2000/svg' width='19.979' height='19.967'>
      <path
         d='M519.835 546.49a.966.966 0 0 1-.3.846l-2.813 2.791a1.6 1.6 0 0 1-.5.36 2.05 2.05 0 0 1-.6.19c-.014 0-.056 0-.127.011s-.162.011-.275.011a9.726 9.726 0 0 1-1.3-.138 9.866 9.866 0 0 1-2.2-.677 18.442 18.442 0 0 1-2.951-1.618 21.992 21.992 0 0 1-3.511-2.961 23.149 23.149 0 0 1-2.453-2.792 19.887 19.887 0 0 1-1.566-2.464 13.269 13.269 0 0 1-.888-2.052 10.994 10.994 0 0 1-.4-1.586 5.276 5.276 0 0 1-.084-1.047q.021-.381.021-.423a2.05 2.05 0 0 1 .19-.6 1.6 1.6 0 0 1 .36-.5l2.813-2.813a.932.932 0 0 1 .677-.3.787.787 0 0 1 .486.159 1.481 1.481 0 0 1 .36.391l2.263 4.294a1.03 1.03 0 0 1 .106.74 1.328 1.328 0 0 1-.36.677l-1.036 1.036a.339.339 0 0 0-.074.138.5.5 0 0 0-.032.158 3.779 3.779 0 0 0 .381 1.015 9.338 9.338 0 0 0 .782 1.238 13.745 13.745 0 0 0 1.5 1.681 13.945 13.945 0 0 0 1.696 1.517 9.791 9.791 0 0 0 1.237.793 3.281 3.281 0 0 0 .762.306l.264.053a.473.473 0 0 0 .137-.032.339.339 0 0 0 .138-.074l1.206-1.226a1.294 1.294 0 0 1 .888-.339 1.111 1.111 0 0 1 .571.127h.021l4.082 2.411a1.064 1.064 0 0 1 .529.7zm0 0'
         transform='translate(-499.866 -530.732)'
      />
   </svg>
);

export default Phone;
