// @flow
import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image/withIEPolyfill';
import styles from './styles.module.scss';

const Image = () => (
   <StaticQuery
      query={graphql`
         query {
            placeholderImage: file(
               relativePath: { eq: "ungelt_city_west_03.jpg" }
            ) {
               childImageSharp {
                  fluid(maxWidth: 450) {
                     ...GatsbyImageSharpFluid
                  }
               }
            }
         }
      `}
      render={data => (
         <div className={styles.wrapper}>
            <Img
               objectFit='cover'
               objectPosition='50% 50%'
               className={styles.image}
               fluid={data.placeholderImage.childImageSharp.fluid}
            />
         </div>
      )}
   />
);

export default Image;
