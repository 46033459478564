// @flow
import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image/withIEPolyfill';
import { injectIntl } from 'react-intl';

import styles from './styles.module.scss';
import Label from '#/Label/Label';
import Heading from '#/SectionHeading/SectionHeading';
import Item from './Item';
import Phone from './icons/Phone';
import Mail from './icons/Mail';
import Mark from './icons/Mark';
import Arrow from '#/Arrow/ArrowVertical';

const query = graphql`
   query {
      image: file(relativePath: { eq: "annie-spratt-small.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 800, quality: 85) {
               ...GatsbyImageSharpFluid
            }
         }
      }
   }
`;

const Contact = ({ intl }) => (
   <StaticQuery
      query={query}
      render={({ image }) => (
         <section id='contact' className={styles.section}>
            <div className={styles.background}>
               <Img
                  style={{ position: 'absolute' }}
                  fluid={image.childImageSharp.fluid}
                  objectFit='cover'
                  objectPosition='50% 50%'
               />
            </div>
            <div className={`${styles.content} container`}>
               <Label>{intl.formatMessage({ id: 'contact.label' })}</Label>
               <Heading className={styles.heading}>
                  {intl.formatMessage({ id: 'contact.heading' })}
               </Heading>
               <div className={styles.contacts}>
                  <Item
                     text='(420) 224 810 979'
                     link='tel:+420224810979'
                     text2='(420) 226 222 101'
                     link2='tel:+420226222101'
                  >
                     <Phone />
                  </Item>
                  <Item text='info@srg.cz' link='mailto:info@srg.cz'>
                     <Mail />
                  </Item>
                  <Item
                     text='Klimentská 1246/1 Praha 1 / 110 00'
                     link='https://goo.gl/maps/7CV38NZxMTx9vtyT9'
                  >
                     <Mark />
                  </Item>
               </div>
            </div>
            <a className={styles.arrow} href='#landing'>
               <Arrow />
            </a>
         </section>
      )}
   />
);

export default injectIntl(Contact);
