// @flow
import React from 'react';

const Mail = () => (
   <svg xmlns='http://www.w3.org/2000/svg' width='17.801' height='13.986'>
      <g transform='translate(-272.139 -533.74)'>
         <path d='M273.848 538.051q.338.238 2.036 1.415t2.6 1.813l.422.3q.322.234.536.378c.142.1.315.2.517.323a3.041 3.041 0 0 0 .571.268 1.6 1.6 0 0 0 .5.089h.02a1.6 1.6 0 0 0 .5-.089 3.135 3.135 0 0 0 .571-.268 11.775 11.775 0 0 0 1.052-.701l.423-.3q.914-.636 4.649-3.228a4.72 4.72 0 0 0 1.211-1.222 2.62 2.62 0 0 0 .487-1.5 1.6 1.6 0 0 0-1.589-1.589h-14.625a1.419 1.419 0 0 0-1.178.517 2.007 2.007 0 0 0-.412 1.291 2.317 2.317 0 0 0 .547 1.356 4.91 4.91 0 0 0 1.162 1.147zm0 0' />
         <path d='M288.947 539.114q-3.258 2.2-4.947 3.427-.566.417-.919.65a5.645 5.645 0 0 1-.938.477 2.856 2.856 0 0 1-1.093.244h-.02a2.852 2.852 0 0 1-1.093-.244 5.62 5.62 0 0 1-.939-.477 23.393 23.393 0 0 1-.918-.65q-1.342-.984-4.938-3.427a5.3 5.3 0 0 1-1-.864v7.887a1.595 1.595 0 0 0 1.59 1.589h14.622a1.595 1.595 0 0 0 1.589-1.589v-7.887a5.484 5.484 0 0 1-.993.864zm0 0' />
      </g>
   </svg>
);

export default Mail;
