// @flow
import React from 'react';

import styles from './styles.module.scss';

const Footer = () => (
   <footer className={styles.footer}>
      <p>
         © Copyright by Ungelt Services, all rights reserved / Designed by
         Benjamin Buttler 2018
      </p>
   </footer>
);

export default Footer;
