// @flow
import React from 'react';
import { injectIntl } from 'react-intl';
import Image from './Image';

import Heading from '#/MainHeading';
import styles from './styles.module.scss';
import Arrow from '#/Arrow/ArrowVertical';

const HomeLanding = ({ intl }) => (
   <section id='landing' className={styles.landing}>
      <Image />
      <div className={`${styles.content} container`}>
         <div className={styles.inner}>
            <h4 className={styles.subheading}>UNGELT GROUP</h4>
            <Heading>
               {intl.formatMessage({
                  id: 'landing.heading',
               })}
            </Heading>
            <a href='#projects' className={styles.anchor}>
               <span>
                  {intl.formatMessage({
                     id: 'landing.cta',
                  })}
               </span>
            </a>
         </div>
      </div>
      <div className={`${styles.lower} container`}>
         <a href='#about' className={styles.arrow}>
            <Arrow />
         </a>
      </div>
   </section>
);

export default injectIntl(HomeLanding);
