// @flow
import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { injectIntl } from 'react-intl';

import styles from './styles.module.scss';
import Item from './Item';

const query = graphql`
   query {
      image1: file(relativePath: { eq: "ungelt_obecni_dvur_01.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 700, quality: 85) {
               ...GatsbyImageSharpFluid
            }
         }
      }
      image2: file(relativePath: { eq: "smichov_city.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 700, quality: 85) {
               ...GatsbyImageSharpFluid
            }
         }
      }
   }
`;

const Info = ({ intl }: { intl: any }) => (
   <StaticQuery
      query={query}
      render={({ image1, image2 }) => (
         <section id='mission' className={styles.section}>
            <div className={`${styles.content} container`}>
               <Item
                  label={intl.formatMessage({ id: 'info.mission.label' })}
                  heading={intl.formatMessage({ id: 'info.mission.heading' })}
                  text={intl.formatMessage({ id: 'info.mission.paragraph' })}
                  image={image1.childImageSharp.fluid}
               />
               <Item
                  large
                  label={intl.formatMessage({ id: 'info.vision.label' })}
                  heading={intl.formatMessage({ id: 'info.vision.heading' })}
                  text={intl.formatMessage({ id: 'info.vision.paragraph' })}
                  image={image2.childImageSharp.fluid}
               />
            </div>
         </section>
      )}
   />
);

export default injectIntl(Info);
