// @flow
import React from 'react';

import Landing from './Landing';
import About from './About';
import Projects from './Projects';
import Info from './Info';
import Stats from './Stats';
import Contact from './Contact';

const Homepage = () => (
   <>
      <Landing />
      <About />
      <Projects />
      <Info />
      <Stats />
      <Contact />
   </>
);

export default Homepage;
